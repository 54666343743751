<div class="select-or-add-cont flex-inputs-cont">
  <mat-form-field
    appearance="outline"
    class="flex-half flex-input"
    [ngClass]="{
      'ng-invalid mdc-text-field--invalid':
        (inputControl.touched || selectedFormControl.touched) &&
        selectedFormControl.invalid
    }"
  >
    <input
      type="text"
      placeholder="{{ 'APP.TYPE_IN_OR_SELECT' | translate }}"
      matInput
      [matAutocomplete]="auto"
      [formControl]="inputControl"
    />
    <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>
    @if (
      requiredError !== "" &&
      (inputControl.touched || selectedFormControl.touched) &&
      selectedFormControl.hasError("required")
    ) {
      <mat-error class="mat-error-outside">
        {{ requiredError | translate }}
      </mat-error>
    }
  </mat-form-field>
  @if (addNew) {
    <div>{{ "APP.OR" | translate }}</div>
    <div class="btn btn-primary" (click)="addAndSelect()">
      {{ "APP.ADD_NEW" | translate }}
    </div>
  }
</div>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option
    *ngFor="let category of filteredCategories | async"
    [value]="category.id"
    (click)="optionClicked($event, category.id)"
  >
    <div>
      <mat-checkbox
        [checked]="isSelected(category.id)"
        (change)="toggleSelection(category.id)"
        (click)="$event.stopPropagation()"
      >
        {{ category.title }}
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>

<mat-chip-set class="autocomplete-select-chip-set">
  <mat-chip
    *ngFor="let category of selectedCategories"
    selectable
    removable
    (removed)="remove(category)"
    class="autocomplete-list-chip"
  >
    {{ getDisplayName(category) }}
    <span matChipRemove><i class="far fa-times"></i></span>
  </mat-chip>
</mat-chip-set>
