import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { EventCategory } from '@models/event-categories/event-category.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-category-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatChipsModule,
    MatCheckboxModule,
  ],
  templateUrl: './category-select.component.html',
  styleUrl: './category-select.component.scss',
})
export class CategorySelectComponent implements OnInit {
  private _eventCategories: EventCategory[] = [];
  private valueChangesSet = false;

  @Input() form!: FormGroup;
  @Input() selectedFormControlName: string = '';
  @Input() set eventCategories(eventCategories: EventCategory[]) {
    if (eventCategories.length > 0) {
      this._eventCategories = eventCategories;
      if (!this.valueChangesSet) {
        this.filteredCategories = this.inputControl.valueChanges.pipe(
          startWith<string | null>(''),
          map((value) => (typeof value === 'string' ? value : this.lastFilter)),
          map((filter) => this.filter(filter)),
        );
      }
    }
  }

  get eventCategories(): EventCategory[] {
    return this._eventCategories;
  }

  @Input() requiredError: string = '';

  @Input() addNew = false;

  @Output() refreshCategories = new EventEmitter<void>();

  inputControl = new FormControl<string>('');

  selectedCategories: number[] = new Array<number>();

  filteredCategories: Observable<EventCategory[]> = new Observable();
  lastFilter: string = '';

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.selectedCategories =
      this.form.controls[this.selectedFormControlName].value;

    this.form.controls[this.selectedFormControlName].valueChanges.subscribe(
      (val) => (this.selectedCategories = val),
    );
  }

  filter(filter: string): EventCategory[] {
    this.lastFilter = filter;
    if (filter) {
      return this.eventCategories.filter((option) => {
        return option.title.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.eventCategories.slice();
    }
  }

  optionClicked(event: Event, eventCategoryId: number) {
    event.stopPropagation();
    this.toggleSelection(eventCategoryId);
  }

  toggleSelection(eventCategoryId: number) {
    if (!this.isSelected(eventCategoryId)) {
      this.selectedCategories.push(eventCategoryId);
    } else {
      this.remove(eventCategoryId, false);
    }

    this.inputControl.setValue('');
    this.form.controls[this.selectedFormControlName].setValue(
      this.selectedCategories,
    );
  }

  remove(eventCategoryId: number, updateFormValue = true): void {
    const index = this.selectedCategories.findIndex(
      (selectedId) => selectedId === eventCategoryId,
    );

    if (index >= 0) {
      this.selectedCategories.splice(index, 1);

      if (updateFormValue) {
        this.form.controls[this.selectedFormControlName].setValue(
          this.selectedCategories,
        );
      }
    }
  }

  isSelected(eventCategoryId: number): boolean {
    return !!this.selectedCategories.find(
      (selectedId) => selectedId === eventCategoryId,
    );
  }

  displayFn(_: string): string {
    return '';
  }

  getDisplayName(eventCategoryId: number) {
    const category = this.eventCategories.find(
      (category) => category.id === eventCategoryId,
    );
    return category?.title;
  }

  async addAndSelect() {
    const { EventCategoryOverlayPageComponent } = await import(
      '@modules/admin/event-categories/dialogs/event-category-overlay-page/event-category-overlay-page.component'
    );
    const dialogRef = this.dialog.open(EventCategoryOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.category) {
        this.eventCategories.push(res.category);
        this.toggleSelection(res.category.id);
        this.refreshCategories.emit();
      }
    });
  }

  get selectedFormControl(): AbstractControl {
    return this.form.controls[this.selectedFormControlName];
  }
}
